button {
  border: 2px solid #202020;
  border-radius: 12px;
  padding: 8px;
  font-size: medium;
  background-color: #eeeeee;
}

button:hover {
  background-color: #30D970;
}

button:active {
  background-color: #26b05c;
}

body {
  background: #444;
}

a {
  color: cornflowerblue;
}

h1, h2 {
  margin-top: 0px;
  margin-bottom: 12px;
  text-align: center;
}

.uiRoot {
  margin: auto;
  width: 90%;
  max-width: 500px;
}

.uiSection {
  background: #1f1f21;
  color: #ffffff;
  padding: 20px;
  border-radius: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.warning {
  color: #e55b13;
  font-weight: bold;
}
